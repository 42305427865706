/* body {
  font-family: 'Poppins', sans-serif;
} */
.coming-soon-container {
  height: 100vh;
  background: linear-gradient(247.82deg, #2E8CC2 -28.13%, #252D51 67.11%);
  color: white;
}

.logo {
  max-width: 300px;
}

@media (min-width: 768px) {
  .logo {
    max-width: 400px;
  }
  
  .display-4 {
    font-size: 48px;
  }
  
  .lead {
    font-size: 24px;
  }
}