@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  letter-spacing: 1.24px;
  color: #ffffff;
}

.lato {
  font-family: "Lato", sans-serif;
}

.bebas-neue {
  font-family: "Bebas Neue", sans-serif;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.cursor-pointer {
  cursor: pointer !important;
}

.font-micro {
  font-size: 5.93px;
  line-height: 8.89px;
  letter-spacing: 0.16px;
}

.font-nano {
  font-size: 4.95px;
  line-height: 140%;
  letter-spacing: 0.19px;
}

.font-xxs {
  font-size: 11.54px;
  line-height: 140%;
  letter-spacing: 0.72px;
}

.font-xs {
  font-size: 13.1px;
  line-height: 140%;
  letter-spacing: 0.5px;
}

.font-s {
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.4px;
}

.font-sm {
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 1.24px;
}

.font-md {
  font-size: 18.32px;
  line-height: 140%;
  letter-spacing: 1.43px;
}

.font-lg {
  font-size: 24px;
  line-height: 140%;
  letter-spacing: 1.24px;
}

.font-xl {
  font-size: 27.84px;
  line-height: 140%;
  letter-spacing: 1.44px;
}

.font-bold {
  font-weight: bold;
}

.font-semibold {
  font-weight: 600;
}

// Hero
.side-grid .faded-image {
  opacity: 0.3;
  filter: grayscale(100%);
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.animated-grid .center-image {
  transition: opacity 0.7s ease-in-out;
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.faded-grid .faded-image:hover {
  opacity: 0.4;
}

.custom-col-width {
  width: 10vw;
}
.custom-col-width-center {
  width: 30vw;
}

.left-half-visible {
  // margin-left: -1rem;
  transform: translateX(-20%);
  padding-right: 0 !important;
}

.right-half-visible {
  // margin-right: -1rem;
  transform: translateX(30%);
  padding-left: 0 !important;
}

.section-bg {
  background-image: url("./assets/about_page_bg.png");
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
}

.home-bg {
  background-image: url("./assets/about_page_bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-blur {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(1, 1, 1, 0.4) 100%
    ),
    linear-gradient(
      358.29deg,
      rgba(0, 0, 0, 0.49) 8.69%,
      rgba(0, 0, 0, 0.7) 96.45%
    );
  backdrop-filter: blur(37.599998474121094px);

  // box-shadow: 26px 39px 7.3px -1px rgba(0, 0, 0, 0.39);
}

.privacy-bg {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(1, 1, 1, 0.4) 100%
    ),
    linear-gradient(
      358.29deg,
      rgba(0, 0, 0, 0.49) 8.69%,
      rgba(0, 0, 0, 0.7) 96.45%
    );

  backdrop-filter: blur(37.599998474121094px);
  box-shadow: 26px 39px 7.3px -1px rgba(0, 0, 0, 0.39);
  margin-top: 48px;
  width: 90vw;
  min-height: 100vh;
}

.about-content-bg {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(1, 1, 1, 0.4) 100%
    ),
    linear-gradient(
      358.29deg,
      rgba(0, 0, 0, 0.49) 8.69%,
      rgba(0, 0, 0, 0.7) 96.45%
    );
  backdrop-filter: blur(37.6px);
  width: 90vw;
  height: 95vh;
}
.card-bg {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(1, 1, 1, 0.4) 100%
    ),
    linear-gradient(
      358.29deg,
      rgba(0, 0, 0, 0.49) 8.69%,
      rgba(0, 0, 0, 0.7) 96.45%
    );
}

@keyframes fadeIn {
  0% {
    background: rgba(0, 0, 0, 0);
    opacity: 1;
  }

  100% {
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
  }
}

.button-hover-interaction {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes backToNormal {
  0% {
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
  }

  50% {
    background: rgba(0, 0, 0, 0.7);
    opacity: 1;
  }

  100% {
    background: rgba(0, 0, 0, 0);
    opacity: 1;
  }
}

.button-clicked-interaction {
  animation: backToNormal 1s ease-in-out;
}

.bg-gray-50 {
  background: linear-gradient(90deg, #000000 50%, #4e4e4e 50%);
}

.font-upper {
  text-transform: uppercase;
}

.bg-medium {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 90%,
      rgba(11, 11, 11, 0.3) 95%
    ),
    url("./assets/footer_bg_right.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

.bg-small {
  background-color: rgb(0, 0, 0);
}

.nav-route {
  position: relative;
  overflow: hidden;
}
.custom-button {
  transition: transform 0.3s ease-out;
  transform-origin: left;
}

.custom-button:hover {
  transform: scale(1.1);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
}

.nav-route:hover::after {
  content: "";
  position: absolute;
  right: 0px; /* Adjust the position of the line */
  top: 20%;
  transform: translateY(-50%) scaleX(0);
  height: 2px;
  width: 50px; /* Length of the line */
  background-color: blue;
  animation: bounce-in-right 0.6s forwards;
}

@keyframes bounce-in-right {
  0% {
    transform: translateY(-50%) scaleX(1);
  }
  50% {
    transform: translateY(-50%) scaleX(3); /* Slightly overshoot */
  }
  100% {
    transform: translateY(-50%) scaleX(1); /* Settle to normal scale */
  }
}

.bounce-in-right {
  animation: bounce-in-right 2s ease infinite;
}

@keyframes bounce-in-right {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(-30px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes bounce-in-right-link {
  0% {
    opacity: 0;
    transform: translateX(150%);
  }
  30% {
    opacity: 1;
    transform: translateX(120%);
  }

  50% {
    opacity: 1;
    transform: translateX(-20%);
  }
  80% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0);
  }
}

.bounce-in-right-link {
  animation: bounce-in-right-link 0.5s forwards;
}

.privacy-policy-headings {
  font-family: Lato;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  letter-spacing: 1.24;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.privacy-policy-text {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 1.24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.text-container {
  display: inline-block;
}

.break-text {
  display: inline;
}

@media (max-width: 767px) {
  .about-cards {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(1, 1, 1, 0.4) 100%
      ),
      linear-gradient(
        358.29deg,
        rgba(0, 0, 0, 0.49) 8.69%,
        rgba(0, 0, 0, 0.7) 96.45%
      );
    backdrop-filter: blur(37.6px);
    box-shadow: 26px 39px 7.3px -1px rgba(0, 0, 0, 0.39);
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .font-lg {
    font-size: 12.13px;
    line-height: 140%;
    letter-spacing: 0.63px;
  }

  .font-sm {
    font-size: 8.08px;
    line-height: 140%;
    letter-spacing: 0.63px;
  }

  .about-content-bg {
    background: none;
    backdrop-filter: none;
  }

  .side-grid .faded-image {
    opacity: 0.3;
    filter: grayscale(100%);
    width: 67.42px;
    height: 67.42px;
    object-fit: cover;
  }

  .animated-grid .center-image {
    transition: opacity 0.7s ease-in-out;
    width: 67.42px;
    height: 67.42px;
    object-fit: cover;
  }

  .custom-col-width {
    width: 10vw;
  }
  .custom-col-width-center {
    width: 50vw;
  }

  .left-half-visible {
    // margin-left: -1rem;
    transform: translateX(-40%);
    padding-right: 0 !important;
  }

  .right-half-visible {
    // margin-right: -1rem;
    transform: translateX(10%);
    padding-left: 0 !important;
  }

  .bg-gray-50 {
    background: linear-gradient(
      90deg,
      #000000 0%,
      #000000 80%,
      #4e4e4e 80%,
      #4e4e4e 100%
    );
  }
}

@media (min-width: 1300px) and (max-width: 1550px) {
  .break-text {
    display: block;
  }
}
